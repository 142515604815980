import { template as template_d66da05ae5c3453c96d6f48c462afdd3 } from "@ember/template-compiler";
const WelcomeHeader = template_d66da05ae5c3453c96d6f48c462afdd3(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
