import { template as template_5877e9b7e954435b8a6db356fbc60ede } from "@ember/template-compiler";
const FKControlMenuContainer = template_5877e9b7e954435b8a6db356fbc60ede(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
