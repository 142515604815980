import { template as template_f4e60538deb54776af827819e71eb55f } from "@ember/template-compiler";
const FKText = template_f4e60538deb54776af827819e71eb55f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
